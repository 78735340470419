import { CurlGenerator } from "curl-generator"
export default class RequestToCurl {
  constructor(requestOptions, options) {
    this.requestOptions = requestOptions
    this.options = options
  }

  // Convert Request object to cURL command
  convert() {
    const params = this.#parseRequestOptions()
    return CurlGenerator(params)
  }

  #capitalizeHeaderKey(headerKey) {
    return headerKey
      .toLowerCase()
      .split("-")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join("-")
  }

  #filterHeaders() {
    if (!this.options?.blacklist?.headers) {
      return this.requestOptions.headers
    } else {
      const filteredHeaders = new Headers

      this.requestOptions.headers.forEach((value, key) => {
        if (this.options.blacklist.headers.map(header => header.toLowerCase()).includes(key.toLowerCase())) {
          return
        }
        filteredHeaders.set(key, value)
      })

      return filteredHeaders
    }
  }

  #parseRequestOptions() {
    // Filter and format headers from the Request object
    const filteredHeaders = this.#filterHeaders()
    const headers = {}
    for (const entry of filteredHeaders.entries()) {
      headers[this.#capitalizeHeaderKey(entry[0])] = entry[1]
    }

    let parsing = {
      url: `'${this.requestOptions.url}'`,
      method: this.requestOptions.method,
      headers: headers
    }

    if (this.requestOptions.body) {
      parsing.body = this.requestOptions.body
    }

    return parsing
  }
}
