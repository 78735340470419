import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["edit", "apiKey", "status", "formVisible", "formHidden"]

  close() {
    const apiKey = this.apiKeyTarget.value.trim()
    const isFilled = apiKey.length > 0

    this.statusTarget.textContent = isFilled ? "Filled" : "Empty"
    this.statusTarget.classList.toggle("filled", isFilled)
    this.statusTarget.classList.toggle("empty", !isFilled)

    this.formHiddenTargets.forEach((element) =>{
      element.removeAttribute("hidden")
    })

    this.formVisibleTargets.forEach((element) =>{
      element.setAttribute("hidden", true)
    })
  }

  edit() {
    this.formVisibleTargets.forEach((element) =>{
      element.removeAttribute("hidden")
    })

    this.formHiddenTargets.forEach((element) =>{
      element.setAttribute("hidden", true)
    })
  }
  
  highlightForm() {
    this.element.dataset.formHighlight = true

    setTimeout(() => {
      delete this.element.dataset.formHighlight
    }, 1000)
  }
}
