import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["apiKey", "requestForm", "serverUrl", "server", "button", "operationLink"]

  static values = { documentationId: String }

  connect() {
    this.localStorageName = `documentation:${this.documentationIdValue}:api-key`

    // Get the serverId from current URL at controller loading
    const serverId = new URLSearchParams(window.location.search).get("server_id")
    // Set the server_id query param on operation links
    if (serverId) {
      this.#setServerIdQueryParamForOperationLinks(serverId)
    }
    this.#setDomApiKeyFromLocalStorage()
  }

  changeServer(event) {
    const server = event.currentTarget.parentElement

    const serverId = server.dataset.id
    this.#setServerIdQueryParamForOperationLinks(serverId)


    // Note: this inter-controller communication works only because
    // both controllers are attached to the same DOM element
    const sendRequestController = this.application.getControllerForElementAndIdentifier(this.element, "explorer-send-request")
    this.serverUrlTarget.textContent = server.dataset.url
    this.#setDomApiKeyFromLocalStorage()
    sendRequestController.updateServer(server.dataset.url)

    // update current URL using pushState to avoid page reload
    let params = new URLSearchParams(window.location.search)
    params.set("server_id", serverId)
    const newUrl = new URL(window.location)
    newUrl.search = params.toString()
    history.pushState({}, "", newUrl)
  }

  resetApiKey() {
    this.apiKeyTarget.value = ""
    const apiKeys = this.#retrieveApiKeysFromLocalStorage()
    delete apiKeys[this.serverUrlTarget.textContent]
    localStorage.setItem(this.localStorageName, JSON.stringify(apiKeys))
    this.dispatch("updatedApiKey", { detail: { apiKey: "" } })
  }

  // let users get entered tokens back when switching servers
  // TODO: add js helper debounce
  updateApiKey(event) {
    const apiKey = event.currentTarget.value
    const apiKeys = this.#retrieveApiKeysFromLocalStorage()
    apiKeys[this.#currentServerLocalStorageKey()] = apiKey
    localStorage.setItem(this.localStorageName, JSON.stringify(apiKeys))
    this.dispatch("updatedApiKey", { detail: { apiKey: apiKey } })
  }

  highlightButton() {
    this.buttonTarget.dataset.buttonHighlight = true
    this.buttonTarget.focus()

    setTimeout(() => {
      delete this.buttonTarget.dataset.buttonHighlight
    }, 1000)
  }

  #setDomApiKeyFromLocalStorage() {
    if (this.#hasNoApiKey()) {
      return
    }
    const apiKey = this.#getLocalStorageApiKey() || ""
    this.apiKeyTarget.value = apiKey
    this.dispatch("updatedApiKey", { detail: { apiKey: apiKey } })
  }

  #retrieveApiKeysFromLocalStorage() {
    return JSON.parse(localStorage.getItem(this.localStorageName)) || {}
  }

  #currentServerLocalStorageKey() {
    return this.serverUrlTarget.textContent.trim()
  }

  #setServerIdQueryParamForOperationLinks(serverId) {
    if (!serverId) {
      return
    }

    this.operationLinkTargets.forEach((operationLink) => {
      const url = new URL(operationLink.href)
      let operationLinkParams = new URLSearchParams(url.search)

      operationLinkParams.set("server_id", serverId)

      url.search = operationLinkParams.toString()
      operationLink.href = url.toString()
    })
  }

  #hasNoApiKey() {
    return this.apiKeyTargets.length === 0
  }

  #getLocalStorageApiKey() {
    const apiKeys = this.#retrieveApiKeysFromLocalStorage()
    return apiKeys[this.#currentServerLocalStorageKey()]
  }
}
